import React from 'react';
import classes from './DropDown.module.scss';

type DropDownItemProps = {
    key: string | number;
    value?: number | string;
    data?: string | object;
    classNames?: object;
    children?: React.ReactNode;
    onClick: (e) => void | Function | any;
    listStyle?: object | any;
    id?: string;
}

const DropDownItem: React.FC<DropDownItemProps> = ({
    key,
    value,
    id,
    data = {},
    classNames,
    children,
    listStyle = {},
    onClick
}) => {
    return (
        <li
            key={key}
            id={id}
            value={value}
            data-item={JSON.stringify(data)}
            className={classes['rb-dropdown-item']}
            onClick={onClick}
            style={{ 
                width: '100%',
                ...listStyle
            }}
        >
            {children}
        </li>
    )
}

export default DropDownItem;