import React from 'react'

const ArrowRight = ({ width, height, color }) => {
  return (
    <svg xmlns="https://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
<path d="M9 17.25L15 11.25L9 5.25" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
  )
}

export default ArrowRight