import React, { useState, ReactNode, useEffect } from 'react';
import Logo from '../logo/Logo';
import Typography from '../typography/Typography';
import Skeleton from '@mui/material/Skeleton';
import classes from './Sidebar.module.scss';
import { useRouter } from 'next/router';
import theme from '../../theme/Theme';
import { useTranslation } from 'next-i18next';
import { getServicesQuery } from '../../../redux/services/actions';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../../redux/auth/actions';
import { Analytics, ACTIONS } from '../../../settings/analytics';
import { businessDataQuery as getBusiness, setBusinessInfo, setCurrentFlow } from '../../../redux/business/actions';
import { customer as getCustomer, setCustomerUpdate } from '../../../redux/customer/actions';

// Icons, they should be replaced later
import homeIcon from '../../../../public/icons/sidebar/homeIcon.svg';
import exchangeIcon from '../../../../public/icons/sidebar/exchangeIcon.svg';
import ratesIcon from '../../../../public/icons/sidebar/ratesIcon.svg';
import recipientIcon from '../../../../public/icons/sidebar/recipientIcon.svg';
import rewardIcon from '../../../../public/icons/sidebar/rewardIcon.svg';
import sendMoney from '../../../../public/icons/sidebar/sendMoney.svg';
import transIcon from '../../../../public/icons/sidebar/transIcon.svg';
import walletIcon from '../../../../public/icons/sidebar/walletIcon.svg';
import homeIconGrey from '../../../../public/icons/sidebar/homeIconGrey.svg';
import exchangeIconGrey from '../../../../public/icons/sidebar/exchangeIconGrey.svg';
import ratesIconGrey from '../../../../public/icons/sidebar/ratesIconGrey.svg';
import recipientIconGrey from '../../../../public/icons/sidebar/recipientIconGrey.svg';
import rewardIconGrey from '../../../../public/icons/sidebar/rewardIconGrey.svg';
import sendMoneyGrey from '../../../../public/icons/sidebar/sendMoneyGrey.svg';
import transIconGrey from '../../../../public/icons/sidebar/transIconGrey.svg';
import walletIconGrey from '../../../../public/icons/sidebar/walletIconGrey.svg';
import mobileTopUpIcon from '../../../../public/icons/sidebar/mobileTopUpIcon.svg';
import giftCardIcon from '../../../../public/icons/sidebar/giftCardIcon.svg';
import billPaymentIcon from '../../../../public/icons/sidebar/billPaymentIcon.svg';
import eSimIcon from '../../../../public/icons/sidebar/eSimIcon.svg'
import DashboardTopBar from './TopBar/DashboardTopBar';
import useCustomMediaQuery from '../../customHook';
import BottomNavBar from './BottomNavBar/BottomNavBar';
import Container from '../container/Container';
import Spacing from '../../styles/spacing/Spacing';
import Box from '../box/Box';

interface SidebarProps {
    children: ReactNode;
    withoutTopBarInMobile: boolean;
    withoutTopBar: boolean;
    withoutBottomNavBar: boolean;
    withBackButton?: boolean;
    withBackButtonOnMobile?: boolean;
    withoutTopBarAndPaddingInMobile?: boolean;
    withOutNotification?: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({
    children,
    withoutTopBarInMobile,
    withoutTopBar,
    withoutBottomNavBar,
    withBackButton = false,
    withBackButtonOnMobile = false,
    withoutTopBarAndPaddingInMobile = false,
    withOutNotification = false
}) => {
    const [menus, setMenus] = useState(null);
    const router = useRouter();
    const mobileView = useCustomMediaQuery(`(max-width: ${theme.breakpoints.lg}px)`);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { account_type } = useSelector((state: any) => state.business);

    let list = account_type === 'personal' ? [
        { path: '/dashboard', label: 'dashboard', activeIcon: homeIcon, icon: homeIconGrey },
        { path: '/send-money', label: 'send_money', activeIcon: sendMoney, icon: sendMoneyGrey },
        { path: '/exchange-currency', label: 'exchange_currency', activeIcon: exchangeIcon, icon: exchangeIconGrey },
        { path: '/recipients', label: 'recipients', activeIcon: recipientIcon, icon: recipientIconGrey },
        { path: '/rewards', label: 'rewards', activeIcon: rewardIcon, icon: rewardIconGrey },
        { path: '/rates', label: 'rates', activeIcon: ratesIcon, icon: ratesIconGrey },
    ] : [
        { path: '/business-account/dashboard', label: 'dashboard', activeIcon: homeIcon, icon: homeIconGrey },
        { path: '/send-money', label: 'send_money', activeIcon: sendMoney, icon: sendMoneyGrey },
        { path: '/exchange-currency', label: 'exchange_currency', activeIcon: exchangeIcon, icon: exchangeIconGrey },
        { path: '/recipients', label: 'recipients', activeIcon: recipientIcon, icon: recipientIconGrey },
        { path: '/rewards', label: 'rewards', activeIcon: rewardIcon, icon: rewardIconGrey },
        { path: '/rates', label: 'rates', activeIcon: ratesIcon, icon: ratesIconGrey },
    ];

    const additionalServices = {
        "mobile": {
            "path": typeof localStorage !== "undefined" && localStorage.getItem('mobile-rec-visited') ?
                '/mobile-top-up/recipient-details/country'
                : "/mobile-top-up",
            "activeIcon": mobileTopUpIcon,
            "icon": mobileTopUpIcon
        },
        "giftcard": {
            "path": typeof localStorage !== "undefined" && localStorage.getItem('gift-card-visited') ?
                '/gift-card/recipient-details'
                : "/gift-card",
            "activeIcon": giftCardIcon,
            "icon": giftCardIcon
        },
        "bills": {
            "path": typeof localStorage !== "undefined" && localStorage.getItem('int-bill-visited') ?
                '/international-bill/recipient-details'
                : "/international-bill",
            "activeIcon": billPaymentIcon,
            "icon": billPaymentIcon
        },
        "esim": {
            "path": "/e-sim",
            "activeIcon": eSimIcon,
            "icon": eSimIcon,
            'new': true
        }
    }

    const getDynamicMenus = async () => {
        const { data } = await getServicesQuery();

        if (data && data.getServices && data.getServices.length) {
            const servicesMapped = data.getServices.map((service: { name: string | number; }) => {
                return {
                    ...service,
                    path: additionalServices[service.name]?.path,
                    activeIcon: additionalServices[service.name]?.activeIcon,
                    label: service?.name,
                    icon: additionalServices[service.name]?.icon,
                    new: additionalServices[service.name]?.new
                }
            })
            list = list.concat(servicesMapped);
            setMenus(list);
            setLoading(false);
        } else setLoading(false);
    }

    const getCustomerInfo = async () => {
        const { success, data } = await getCustomer();
        if (success) {
            dispatch(setCustomerUpdate(data.customer));
        }
        return success;
    }

    const getBusinessInfo = async () => {
        const { success, data } = await getBusiness();
        if (success) {
            dispatch(setBusinessInfo(data.customerBusinessInfo));
        }
        return success;
    }

    useEffect(() => {
        setMenus(list);
        getDynamicMenus();
        // Every time sidebar renders, update customer and business info
        getCustomerInfo();
        getBusinessInfo();
    }, []);

    const handleMenuClick = (path: string) => {
        switch (path) {
            case '/send-money':
                Analytics.track(ACTIONS.SEND_MONEY_INITIATED, {}, { accountType: account_type });
                dispatch(setCurrentFlow('send-money'));
                break;
            case '/exchange-currency':
                Analytics.track(ACTIONS.CURRENCY_EXCHANGE_INITIATED, {}, { accountType: account_type });
                break;
            case '/mobile-top-up':
            case '/mobile-top-up/recipient-details/country':
                Analytics.track(ACTIONS.MOBILE_TOP_UP_INITIATED, {}, { accountType: account_type });
                break;
            case '/gift-card':
            case '/gift-card/recipient-details':
                Analytics.track(ACTIONS.GIFT_CARD_INITIATED, {}, { accountType: account_type });
                break;
            case '/international-bill':
            case '/international-bill/recipient-details':
                Analytics.track(ACTIONS.BILL_PAYMENT_INITIATED, {}, { accountType: account_type });
                break;
        }
        router.push(path);
    }

    const handleLogout = () => {
        dispatch(logout(true, true, null));
    }

    const propsToBePassed = {
        handleLogout,
        withBackButton,
        withBackButtonOnMobile,
        withoutTopBarAndPaddingInMobile,
        withOutNotification
    }

    const renderTopBarConditionally = (propsToBePassed: any) => {
        const shouldRenderTopBar =
            (!withoutTopBarInMobile || !mobileView) && !withoutTopBar;

        return shouldRenderTopBar ? <DashboardTopBar {...propsToBePassed} /> : null;
    };

    return (
        <div className={classes['rb-sidebar-root']}>
            <div className={classes['rb-sidebar-lateral']}>
                <div>
                    <div className={classes['rb-sidebar-logo']}>
                        <Logo accountType={account_type} />
                    </div>
                    <ul className={classes['rb-sidebar-menu']}>
                        {menus && menus.map((menu: { path: string; activeIcon: string; icon: string; label: any; new?: boolean }) => (
                            <li
                                key={`menu-${menu.label}`}
                                id={`menu-${menu.label}`}
                                onClick={() => handleMenuClick(menu.path)}
                                className={`${router.asPath === menu.path ? classes['rb-sidebar-menu-selected'] : ''}`}>
                                
                                <img src={router.asPath === menu.path ? menu.activeIcon : menu.icon} alt={t(`sidebar-options:${menu.label}`)} />
                                <Typography
                                    variant='label1'
                                    weight={router.asPath === menu.path ? 'medium' : 'regular'}
                                    color={router.asPath === menu.path ? theme.palette.primary.blue : theme.palette.primary.navy}
                                    className={classes['rb-sidebar-new-text']}
                                >
                                    {t(`sidebar-options:${menu.label}`)}
                                </Typography>
                                {menu?.new ? <Box className={classes['rb-sidebar-new']}><span>NEW</span></Box> : null}
                            </li>
                        ))}
                        {loading && (
                            <>
                                {Object.keys(additionalServices).map((item, index) => ( // added this line, so that the skeletons will be dynamic based on services
                                    <li key={index}>
                                        <Skeleton variant='circular' width='21px' height='21px' />
                                        <Skeleton height={22} width={100} style={{ width: 'calc(100% - 46px)' }} />
                                    </li>
                                ))}
                            </>
                        )}
                    </ul>
                </div>
                <div className={classes['rb-sidebar-footer']}>
                    <Typography variant='body3'>© Remitbee Inc. 2024</Typography>
                    <Typography variant='smallText' className={classes['rb-sidebar-footer-links']}>
                        <a href='/customer-help' target='_blank'>{'Help Centre'}</a>
                        <span>{'|'}</span>
                        <a href='/terms-and-conditions' target='_blank'>{'Terms of Use'}</a>
                        <span className={classes.separator}>{'|'}</span>
                        <a href='/privacy' target='_blank'>{'Privacy'}</a>
                    </Typography>
                </div>
            </div>
            <div className={classes['rb-sidebar-content']} style={{ marginTop: (withoutTopBarInMobile && mobileView) && 0 }}>
                {renderTopBarConditionally(propsToBePassed)}
                {!(withoutTopBarAndPaddingInMobile && mobileView) ? <Spacing variant='containerVerticalPadding'>
                    <Spacing variant='containerHorizontal'>
                        <div className={classes['rb-sidebar-inner-content']} id='sidebar-inner-content'>
                            <Container type='regular' style={{ margin: 0 }}>
                                {children}
                            </Container>
                        </div>
                    </Spacing>
                </Spacing> : <>{children}</>}
            </div>
            {(!withoutBottomNavBar && mobileView) && <BottomNavBar {...propsToBePassed} />}
        </div>
    );
};

export default Sidebar;