import { apiMainQuery } from '../../settings/apollo/api/main/query';
import { apiMainMutation } from '../../settings/apollo/api/main/mutation';
import { 
    sendCustomerNotificationMutation, notificationHistoryPaginatedQuery, hasUnreadNotificationsQuery, hasUnreadNotificationsV2Query, broadcastNotificationsQuery, sendEmployeeNotificationMutation
} from './graphql.graphql';

//export const escalationSubTypesByType = (params) => apiMainQuery(escalationSubTypesByTypeQuery, params);
export const sendCustomerNotification = (params) => apiMainMutation(sendCustomerNotificationMutation, params);
export const sendEmployeeNotification = (params) => apiMainMutation(sendEmployeeNotificationMutation, params);
export const notificationHistoryPaginated = (params) => apiMainQuery(notificationHistoryPaginatedQuery, params);
export const hasUnreadNotifications = (params) => apiMainQuery(hasUnreadNotificationsQuery, params);
export const hasUnreadNotificationsV2 = (params) => apiMainQuery(hasUnreadNotificationsV2Query, params);
export const broadcastNotificationsInfo = (params) => apiMainQuery(broadcastNotificationsQuery, params);