import React from 'react'

const Help = ({ height = 21, width = 21, color = "#1160F7" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.4728 9.75C9.4728 9.75 9.11488 6.72415 12.4986 6.72415C14.6243 6.72415 15.5245 8.40518 15.5245 9.75C15.5245 10.76 14.9556 11.7713 13.8166 12.211C13.124 12.4786 12.4986 13.0421 12.4986 13.7845V14.1207M12.4986 16.125V16.5M12.4986 20C17.1931 20 20.9986 16.1944 20.9986 11.5C20.9986 6.80558 17.1931 3 12.4986 3C7.80423 3 3.99865 6.80558 3.99865 11.5C3.99865 12.45 4.1545 13.3636 4.44202 14.2166C4.55022 14.5376 4.60432 14.6981 4.61408 14.8214C4.62371 14.9432 4.61643 15.0286 4.5863 15.1469C4.5558 15.2668 4.48844 15.3915 4.35374 15.6408L2.71808 18.6684C2.48477 19.1002 2.36812 19.3161 2.39423 19.4828C2.41697 19.6279 2.5024 19.7557 2.62782 19.8322C2.77183 19.9201 3.01595 19.8948 3.50419 19.8444L8.62521 19.315C8.78029 19.299 8.85783 19.291 8.92851 19.2937C8.99802 19.2963 9.04709 19.3029 9.11488 19.3185C9.1838 19.3344 9.27047 19.3678 9.4438 19.4345C10.3919 19.7998 11.4219 20 12.4986 20Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default Help