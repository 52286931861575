import React from 'react'
import classes from '../DashboardTopBar.module.scss'
import Box from '../../../box/Box';
import Typography from '../../../typography/Typography';
import theme from '../../../../theme/Theme';

interface Props {
    initial?: string | any;
}

const Avatar:React.FC<Props> = ({ initial }) => {
  return (
    <Box className={classes['rb-avatar-initials']}>
        <Typography variant='label2' color={theme.palette.primary.blue} className={classes['rb-avatar-text']}>{initial}</Typography>
    </Box>
  )
}

export default Avatar