import React, {  useRef, useEffect, useState } from 'react';
import classes from './Menu.module.scss';

const MenuDropdown: React.FC<{options: any;
    open: boolean;
    onClose: () => void;
    id: string;
    setOpen: any;
    parentRef: any;
    position: { top?: string, left?: string, right?: string, bottom?: string }
    style?: object;}> = ({ id, open, setOpen, onClose, options, parentRef, position = {}, style = {} }) => {
    const menuRef = useRef<HTMLDivElement>(null);
    const [customStyle, setCustomStyle] = useState({ ...position, ...style });

    useEffect(() => {
        if (menuRef?.current) {
          const height = menuRef?.current.offsetHeight;
          if (height > window.innerHeight) {
            style = { ...style, overflowY: 'auto', paddingTop: '48px', maxHeight: '400px' }
            setCustomStyle(style)
          }
        }
      }, [menuRef?.current, menuRef?.current?.offsetHeight, open]);

    const handleClickOutside = (event: { target: any; }) => {
        if ((menuRef.current && !menuRef.current.contains(event.target)) && (parentRef && !parentRef.current.contains(event.target))) {
            setOpen(false);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Escape') {
            setOpen(false)
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    if (!open) return null
    
    return (
        <div id={id} ref={menuRef} onClick={onClose} className={classes['rb-menu-wrapper']} style={customStyle}>
            <ul className={classes['rb-list-item-container']}>
                {options}
            </ul>
        </div>
    );
};

export default MenuDropdown;
