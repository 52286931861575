import React from 'react'

const Inbox = ({ height = 21, width = 21, color = "#1160F7" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="https://www.w3.org/2000/svg">
            <path d="M2.5 12H5.88197C6.56717 12 7.19357 12.3871 7.5 13C7.80643 13.6129 8.43283 14 9.11803 14H14.882C15.5672 14 16.1936 13.6129 16.5 13C16.8064 12.3871 17.4328 12 18.118 12H21.5M7.12476 5.62505C6.64254 5.62505 6.40144 5.62505 6.18131 5.66943C5.66134 5.77427 5.19513 6.05943 4.86497 6.47459C4.7252 6.65034 4.61539 6.86499 4.39575 7.29427L2.50675 10.9865C2.31776 11.3645 2.22326 11.5535 2.15662 11.7515C2.09744 11.9274 2.05471 12.1085 2.02897 12.2923C2 12.4992 2 12.7105 2 13.1331V15.2C2 16.8802 2 17.7202 2.32698 18.362C2.6146 18.9265 3.07354 19.3854 3.63803 19.673C4.27976 20 5.11984 20 6.8 20H17.2C18.8802 20 19.7202 20 20.362 19.673C20.9265 19.3854 21.3854 18.9265 21.673 18.362C22 17.7202 22 16.8801 22 15.2V13.1331C22 12.7106 22 12.4992 21.971 12.2923C21.9453 12.1085 21.9026 11.9274 21.8434 11.7515C21.7767 11.5535 21.6822 11.3645 21.4932 10.9865L20.034 7.68317C19.7283 6.99133 19.5755 6.64541 19.3432 6.38318C19.0746 6.07987 18.729 5.85474 18.343 5.73156C18.0093 5.62505 17.6311 5.62505 16.8748 5.62505M12 2V9M12 9L14.625 6.375M12 9L9.375 6.375" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default Inbox