import React from 'react'

const Logout = ({ color, width, height }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="https://www.w3.org/2000/svg">
            <path d="M16 16.4667L21 11.7975M21 11.7975L16 7.12821M21 11.7975H9M12 16.4667C12 16.7428 12 16.8808 11.989 17.0003C11.8748 18.2429 10.8949 19.2653 9.58503 19.5085C9.45903 19.5319 9.31202 19.5472 9.01835 19.5777L7.99694 19.6836C6.46248 19.8429 5.69521 19.9225 5.08566 19.7403C4.27293 19.4974 3.60942 18.9429 3.26118 18.2153C3 17.6697 3 16.9488 3 15.507V8.08792C3 6.64612 3 5.92522 3.26118 5.37958C3.60942 4.65208 4.27293 4.09748 5.08566 3.85461C5.69521 3.67246 6.46246 3.75207 7.99694 3.91129L9.01835 4.01727C9.31212 4.04775 9.45901 4.06299 9.58503 4.08639C10.8949 4.3296 11.8748 5.35205 11.989 6.59461C12 6.71417 12 6.85218 12 7.12821" stroke={color}strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default Logout;