import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import React, { useState } from 'react';

const styles = {
  continueButton: {
    display: 'block',
    maxWidth: '500px',
    width: '100%',
    cursor: 'pointer',
    marginLeft: '10px',
    marginTop: '20px',
    fontSize: '20px',
    padding: '10px',
    color: '#FFFFFF',
  },
  divStyles: {
    display: 'flex',
    flexDirection: 'column',
    padding: ' 20px 40px',
    alignContent: 'center',
    justifyContent: 'center',
  },
  innerDivStyles: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignContent: 'center',
  },
  pageNumberStyles: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#31517A',
    fontStyle: 'normal',
  },
  notificationTitleStyles: {
    fontFamily: 'Heebo',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '35px',
    color: '#31517A',
    textAlign: 'center',
  },
};

const ContinueButton = ({ reachedEnd, ...otherProps }) => (
  <Button {...otherProps} variant="contained" color="primary" style={styles.continueButton}>
    {!reachedEnd ? 'Next important message' : 'Got it'}
  </Button>
);

const BroadcastNotificationDialog = (props) => {
  const { notifications, isOpen, onDialogClose } = props;
  const [pageIndex, setPageIndex] = useState(0);

  const onButtonClick = (reachedEnd) => {
    if (reachedEnd) {
      onDialogClose();
    } else {
      setPageIndex((prev) => prev + 1);
    }
  };

  const renderMessage = ({ message_text }) => {
    return (
      <DialogContentText style={{ textAlign: 'center' }} id="alert-dialog-description">
        {message_text} &nbsp;
      </DialogContentText>
    );
  };
  if (notifications && notifications.length) {
    const reachedEnd = notifications.length == 1 || pageIndex == notifications.length - 1;
    return (
      <Dialog open={isOpen} onClose={onDialogClose} fullWidth>
        <div style={styles.divStyles}>
          <div
            style={{
              ...styles.innerDivStyles,
              justifyContent: reachedEnd ? 'flex-end' : 'space-between',
            }}
          >
            {!reachedEnd && (
              <p style={styles.pageNumberStyles}>
                {pageIndex + 1} of {notifications.length}
              </p>
            )}
            <img
              style={{
                cursor: 'pointer',
              }}
              onClick={onDialogClose}
              src={'/img/closeNotification.svg'}
              alt="close-notification"
            />
          </div>
          <img
            src={'/img/notificationIcon.svg'}
            alt="notification icon"
            style={{ height: '95px', marginBottom: '20px' }}
          />
          <h1 style={styles.notificationTitleStyles}>{notifications[pageIndex].message_title}</h1>
          {renderMessage(notifications[pageIndex])}
          <ContinueButton onClick={() => onButtonClick(reachedEnd)} reachedEnd={reachedEnd} />
        </div>
      </Dialog>
    );
  }
  return <></>;
};

export default BroadcastNotificationDialog;
