import React, { useState, useEffect } from 'react';
import { withRouter } from 'next/router';
import { connect } from 'react-redux';
import { logout } from '../redux/auth/actions';
import { store } from '../redux/store';
import { isSessionAlive, setCsrfToken } from '../redux/auth/actions';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import Sidebar from '../remitbee/components/sidebar/Sidebar';
import { snackbar } from '../remitbee/components/snackbar/SnackBar';
import Loader from '../remitbee/components/loader/Loader';

/**
 * Private ADHOC function that it's used to make a private component
 * @param {object} WrappedComponent Component that should be public
 * @param {object} customProps Custom props to be used 
 */
export const withAuthSync = (WrappedComponent, customProps) => {
    const WithAuthSyncClass = (props) => {
        const [loading, setLoading] = useState(true);
        const { withoutSidebar } = customProps || {};
        const { t } = useTranslation();
        const Router = useRouter()

        // const displayName = `withAuthSync(${getDisplayName(WrappedComponent)})`

        const fetchSession = async () => {

            await isSessionAlive()

            const auth = store.getState().auth
            let shouldRedirectToLogin = false
            let errorMessage = t('common:disconnected_inactivity');
            if (!auth.isAuthenticated) {
                shouldRedirectToLogin = true
            }

            if (auth.isAuthenticated && !auth.hasPhoneNumber) {
                shouldRedirectToLogin = true
                errorMessage = t('common:disconnected_phone');
            }

            if (auth.isAuthenticated && auth.hasPhoneNumber && !auth.isPhoneValidated) {
                return Router.push('/verify-phone')
            }

            if (shouldRedirectToLogin) {
                let redirectTo = null;
                if ((props.router || {}).route) {
                    redirectTo = props.router.asPath;
                }
                snackbar.error(errorMessage, 5000);
                store.dispatch(logout(false, true, redirectTo))
            }
            else {
                if (store.getState().business && store.getState().business.account_type === "business" && props.router.asPath === '/dashboard') {
                    props.router.push('/business-account/dashboard');
                    return
                }
                setLoading(false);
                window.addEventListener('storage', syncLogout)
            }
        }

        useEffect(() => {
            fetchSession();
        }, []);

        const syncLogout = event => {
            if (event.key === 'logout') Router.push('/login')
        }

        if (loading) return <Loader />
        else {
            if (withoutSidebar) return <WrappedComponent {...props} />
            return (
                <Sidebar {...customProps}>
                    <WrappedComponent {...props} />
                </Sidebar>
            )
        }
    }

    return withRouter(WithAuthSyncClass)
}

/* HOC created to block access on production/staging for upcoming pages */
export const blockedPage = (WrappedComponent) => {
    const Blocked = (props) => {
        const router = useRouter();
        const [loading, setLoading] = useState(true);

        useEffect(() => {
            if (process.env.REACT_APP_ENV === 'prod' || process.env.REACT_APP_ENV === 'staging') router.push('/404');
            else setLoading(false);
        }, [])

        return loading ? null : <WrappedComponent {...props} />
    }

    return connect(null, { setCsrfToken })(Blocked)
}

/**
 * Public ADHOC function that it's used to make a public component
 * @param {object} WrappedComponent Component that should be public
 * @param {object} customProps Custom props to be used 
 */
export const publicPage = (WrappedComponent, customProps) => { // eslint-disable-line no-unused-vars
    const PublicPageClass = (props) => {
        const router = useRouter();

        useEffect(() => {
            const load = async () => {
                // if (loading) {
                //     // Check via API if user is logged in, if session has expired then no need to redirect anywhere else
                //     // const { success, data } = await hasUnreadNotificationsV2();
                //     // if(!success && !data) {
                //     //     setLoading(false);
                //     //     return;
                //     // }

                //     if (isPage) await setCsrfToken();
                //     //await isSessionAlive()

                //     const auth = store.getState().auth
                //     let shouldRedirectToDashboard = false

                //     if (auth.isAuthenticated && auth.hasPhoneNumber && !auth.isPhoneValidated && Router.asPath === '/verify-phone') {
                //         return setLoading(false);
                //     }
                //     if (auth.isAuthenticated && auth.hasPhoneNumber)
                //         shouldRedirectToDashboard = true

                //     else if (auth.isAuthenticated && !auth.hasPhoneNumber && props.router && props.router.route === '/login')
                //         shouldRedirectToDashboard = true

                //     else if (auth.isAuthenticated && !auth.hasPhoneNumber) {
                //         //shouldRedirectLogin = true
                //     }
                //     else if (auth.isAuthenticated) {
                //         store.dispatch(logout(false, true));
                //     }
                //     // If user is logged in and wants to access the below given pages then we dont redirect to the dashboard
                //     // [ signup, logout, privacy, terms-and-conditions, help, blog ]
                //     if (shouldRedirectToDashboard &&
                //         !shouldIgnoreAuth &&
                //         !(['/signup', '/logout', '/privacy', '/terms-and-conditions', '/help', '/blog', '/referral-competition'].includes(Router.asPath))
                //     ) {
                //         if (auth.isAuthenticated && auth.hasPhoneNumber && !auth.isPhoneValidated) {
                //             return Router.push('/verify-phone');
                //         }
                //         props.accountType === 'personal' ? Router.push('/dashboard') : Router.push('/business-account/dashboard')
                //     }
                //     else setLoading(false)
                // }
                //if (isPage) await setCsrfToken();

                await isSessionAlive();

                const auth = store.getState().auth;
    
                if (auth.isAuthenticated && (router.asPath === '/' || router.asPath === '/index' || router.asPath.includes('login'))) {
                    props.accountType === 'personal' ? router.push('/dashboard') : router.push('/business-account/dashboard');
                }
    
                if (auth.isAuthenticated && auth.hasPhoneNumber && !auth.isPhoneValidated) {
                    return router.replace('/verify-phone');
                }
            }

            load();
        }, []);

        return <WrappedComponent {...props} />
    }

    const mapStateToProps = (state) => ({
        csrfToken: state.auth.csrfToken,
        accountType: state.business.account_type
    })

    return connect(mapStateToProps, { setCsrfToken })(PublicPageClass)
}

export const verifySession = async (t, dispatch, url) => {
    await isSessionAlive();

    const auth = store.getState().auth;

    let shouldRedirectToLogin = false;

    let errorMessage = t('common:disconnected_inactivity');

    if (!auth.isAuthenticated) {
        shouldRedirectToLogin = true
    }

    if (shouldRedirectToLogin) {
        snackbar.error(errorMessage, 5000);
        dispatch(logout(false, true, url));
    }
}

