import React from 'react';

const ArrowUpLined = ({ width, height, color }) => {
  return (
    <svg xmlns="https://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 32 32" fill="none">
      <path
        d="M16 25.3333V6.66663M16 6.66663L6.66669 16M16 6.66663L25.3334 16"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowUpLined;
