import React from 'react';

const CheckIcon = ({ width, height, color = "#1160F7" }) => {
  return (
    <svg xmlns="https://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 10" fill="none">
      <path
        d="M1 5L5 9L13 1"
        stroke={color}
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckIcon;
