import React, { useEffect, useState } from 'react'
import classes from './DashboardTopBar.module.scss'
import LanguagesDropDown from './components/LanguagesDropDown';
import { useSelector } from 'react-redux';
import UserMenu from './components/UserMenu'
import Notifications from './components/Notifications'
import useCustomMediaQuery from '../../../customHook';
import Box from '../../../components/box/Box';
import Container from '../../../components/container/Container';
import Spacing from '../../../styles/spacing/Spacing';
import theme from '../../../theme/Theme';
import ArrowLeft from '../../../icons/ArrowLeft';
import { useRouter } from 'next/router';
import Typography from '../../typography/Typography';
import ChevronLeft from '../../../icons/ChevronLeft';

const DashboardTopBar = ({
  handleLogout,
  withBackButton,
  withBackButtonOnMobile,
  withoutTopBarAndPaddingInMobile,
  withOutNotification,
}) => {
  const customer = useSelector((state) => state.customer);
  const business = useSelector((state) => state.business);
  const isMobile = useCustomMediaQuery(`(max-width: ${theme.breakpoints.lg}px)`);
  const router = useRouter();
  const notIncludeNotifications = ['settings']  // Add the path here where we don't want to show the notifications
  const [hideNotifications, setHideNotifications] = useState(false);

  useEffect(() => {
     if(router?.asPath) {
      const path = router?.asPath?.split('?')[0]?.split('/')[1]
       if(notIncludeNotifications?.includes(path)) {
         setHideNotifications(true)
       } else {
         setHideNotifications(false)
       }
     }
  }, [router?.asPath])

  if (withoutTopBarAndPaddingInMobile && isMobile) return <></>

  return (
    <div className={`${classes['rb-topBar-dashboard-container']}`}>
      <Spacing variant='containerVerticalPadding'>
        <Container type='regular' style={{ margin: 0 }}>
          <Box display='flex'>
            {/* {withBackButton || (withBackButtonOnMobile && isMobile) && ( */}
            {(withBackButton || (withBackButtonOnMobile && isMobile)) && (
              <Box
                onClick={() => router.back()}
                className={classes['rb-dashboard-topBar-back']}
                display='flex'
                alignItems='center'
                style={{ width: 'fit-content', cursor: 'pointer' }}
              >
                {isMobile && <ArrowLeft width={24} height={24} color={theme.palette.primary.blue} />}
                {!isMobile && <Box display='flex'> <ChevronLeft width={26} height={26} color={'#1160F7'} />
                  <Typography variant='body1' color={theme.palette.primary.blue}>Back</Typography></Box>}
              </Box>
            )}
            <Box className={classes['rb-dashboard-topBar']}>
              {(withOutNotification ? !withOutNotification : !hideNotifications) && <Notifications isMobile={isMobile} />}
              {!isMobile && (
                <LanguagesDropDown
                  enabled_languages={['ta', 'si', 'fr', 'es']}
                  isMobile={isMobile}
                />
              )}

              {!isMobile && (
                <UserMenu
                  accountType={business.account_type}
                  businessData={business}
                  customer={customer}
                  handleLogout={handleLogout}
                />
              )}
            </Box>
          </Box>
        </Container>
      </Spacing>
    </div>
  )
}

export default DashboardTopBar;
