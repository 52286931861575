import { useRouter } from 'next/router'
import React, { useEffect, useRef, useState } from 'react'
import classes from '../DashboardTopBar.module.scss'
import Box from '../../../../components/box/Box';
import Typography from '../../../../components/typography/Typography';
import ArrowDown from '../../../../icons/ArrowDown'
import ArrowRight from '../../../../icons/ArrowRight'
import theme from '../../../../theme/Theme';
import MenuDropdown from '../../../../components/menu/Menu';
import { ACTIONS, Analytics } from '../../../../../../src/settings/analytics';
import DropDownItem from '../../../../components/dropDown/DropDownItem';
import CheckIcon from '../../../../icons/CheckIcon';
import ArrowUp from '../../../../icons/ArrowUp';

const languages_list = [
    {
        label: 'English',
        value: 'en',
    },
    {
        label: 'Português',
        value: 'pt',
    },
    {
        label: 'Français',
        value: 'fr',
    },
    {
        label: 'Spanish',
        value: 'es',
    },
    {
        label: 'தமிழ்',
        value: 'ta',
    },
    {
        label: 'සිංහල',
        value: 'si'
    },
    {
        label: 'ਪੰਜਾਬੀ',
        value: 'pa'
    },
    {
        label: 'Wikang Tagalog',
        value: 'tl'
    },
    {
        label: 'Hindi',
        value: 'hi'
    }
];

const getLanguagePaths = (path, enabled_languages) => {
    const basePath = 'https://www.remitbee.com';
    const languageSubpaths = [
        { code: 'fr-CA', subpath: 'fr/' },
        { code: 'ta', subpath: 'ta' },
        { code: 'si', subpath: 'si' },
        { code: 'fr', subpath: 'fr' },
        { code: 'pa', subpath: 'pa' },
        { code: 'hi', subpath: 'hi' },
        { code: 'pt', subpath: 'pt' },
        { code: 'tl', subpath: 'tl' },
        { code: 'es', subpath: 'es' },
        { code: 'vi', subpath: 'vi' },
        { code: 'th', subpath: 'th' },
        { code: 'ur', subpath: 'ur' },
        { code: 'zh', subpath: 'zh' },
        { code: 'te', subpath: 'te' },
        { code: 'gu', subpath: 'gu' },
        { code: 'bn', subpath: 'bn' },
    ];

    const hrefLang = languageSubpaths.map(subpath => enabled_languages.includes(subpath.subpath) ? subpath : null).filter(lang => lang != null);
    let Alternates = null;
    if (path == '/') {
        Alternates = hrefLang.map(lang => ({
            hrefLang: lang.code,
            href: `${basePath}/${lang.subpath}`,
        }));
        Alternates.push({ hrefLang: "x-default", href: `${basePath}` })
    }
    else {
        Alternates = hrefLang.map(lang => ({
            hrefLang: lang.code,
            href: `${basePath}/${lang.subpath}${path}`,
        }));
        Alternates.push({ hrefLang: "x-default", href: `${basePath}${path}` })
    }

    return Alternates;
}

const LanguagesDropDown = ({ enabled_languages, isMobile, showOptionsOnly = false }) => {
    const router = useRouter();
    const [openLanguages, setOpenLanguages] = useState(false);
    const parentRef = useRef<HTMLDivElement>(null)

    let localStoreLanguage = typeof localStorage !== 'undefined' && localStorage?.getItem('i18nextLng');

    let defaultSeo: any = {}
    // const path = window.location.pathname;
    const path = '/dashboard'
    const localeExist = languages_list.some(substring => path.includes(`/${substring.value}/`));
    const modifiedPath = localeExist ? path.substring(3) : path;
    defaultSeo.languageAlternates = getLanguagePaths(modifiedPath, enabled_languages);

    let english = [{
        label: 'English',
        value: 'en',
    }];


    const [selectedLanguage, setSelectedLanguage] = useState('en');


    const setLang = (lang) => {
        typeof localStorage !== 'undefined' && localStorage?.setItem('i18nextLng', lang);
        setSelectedLanguage(lang);
        router.replace(router.asPath, router.asPath, { locale: lang });
    }

    const setLangSettings = () => {
        const defaultLanguage = 'en';

        if (enabled_languages) {
            if (enabled_languages.includes(localStoreLanguage) || localStoreLanguage === 'en') {
                setLang(localStoreLanguage);
                return;
            }

            for (const lang of enabled_languages) {
                if (path.includes(`/${lang}/`) || path === `/${lang}`) {
                    setLang(lang);
                    return;
                }
            }
        }

        setLang(defaultLanguage);
    }

    const onChange = async language => {
        typeof localStorage !== 'undefined' && localStorage?.setItem('i18nextLng', language.value);
        setSelectedLanguage(language.value);
        setOpenLanguages && setOpenLanguages(null);
        Analytics.track(ACTIONS.LANGUAGE_CHANGED, { language: language.value });
        router.replace(router.asPath, router.asPath, { locale: language.value });
    }

    const handleMenu = () => setOpenLanguages(!openLanguages)

    useEffect(() => {
        setLangSettings()
    }, [])

    let other_lang = languages_list.filter(lang => enabled_languages.includes(lang.value))
    const languages = english.concat(other_lang)

    const listOptions = languages.map((lang, index) => {
        return (
            <DropDownItem
                key={index}
                onClick={() => onChange(lang)}
                data={lang}
                value={lang.value}
                listStyle={{ padding: '8px 24px', height: '42px' }}
            >
                <Box display='flex' style={{ minWidth: '130px', width: '100%' }} alignItems='center'>
                    <Box style={{ width: 'fit-content' }} flexGrow={1}><Typography variant="label" color={theme.palette.primary.navy} >{lang.label}</Typography></Box>
                    {lang.value === selectedLanguage && <Box style={{ width: 'fit-content' }}><CheckIcon width={'20'} height={'20'} /></Box>}
                </Box>
            </DropDownItem>
        )
    })

    if (showOptionsOnly) return (
        <div className={classes['rb-language-dropdown-only']}>
            {listOptions}
        </div>
    );

    return (
        <div ref={parentRef} style={{ position: 'relative' }}>
            <Box onClick={handleMenu} className={classes['rb-language-dropdown']}>
                <img src='/img/language_grey.svg' alt="language icon" />
                <Box style={{ width: '30px' }}>
                    <Typography variant="label1" color={theme.palette.primary.navy}>{selectedLanguage}</Typography>
                </Box>
                {!isMobile ?
                    <>
                    {openLanguages ?
                        <ArrowUp width={24} height={24} color={'#1160F7'} /> :
                        <ArrowDown width={24} height={24} color={'#1160F7'} />}
                    </>
                    : <ArrowRight width={24} height={24} color={'#1160F7'} />}
            </Box>

            <MenuDropdown
                id="language-dropdown"
                parentRef={parentRef}
                open={openLanguages}
                onClose={() => setOpenLanguages(false)}
                setOpen={setOpenLanguages}
                options={listOptions}
                position={{ right: '0px' }}
            />
        </div>
    )
}

export default LanguagesDropDown